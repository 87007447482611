import { I18nManager } from 'react-native';
import { createIntl, createIntlCache } from '@formatjs/intl';
import * as RNLocalize from 'react-native-localize';

  const translations = {
    de: require('./de.json'),
    en: require('./en.json'),
    fr: require('./fr.json'),
  } as const;

  type Translation = keyof typeof translations;

  // fallback if no available language fits
  const fallback = { languageTag: 'en', isRTL: false };

  const { languageTag, isRTL } =
  RNLocalize.findBestLanguageTag(Object.keys(translations)) ?? fallback;

  // update layout direction
  I18nManager.forceRTL(isRTL);

  const intl = createIntl(
    {
      defaultLocale: 'en',
      locale: languageTag,
      messages: translations[languageTag as Translation],
    },
    createIntlCache(),
  );

  type TranslationParams = Parameters<(typeof intl)['formatMessage']>[1];

  const translateText = (key: string, params?: TranslationParams) =>
    intl
      .formatMessage({ id: key, defaultMessage: translations.en[key] }, params)
      .toString();


export const Translate = (key:string) => translateText(key);
