/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 */

import * as React from 'react';
// You can import Ionicons from @expo/vector-icons/Ionicons if you use Expo or
// react-native-vector-icons/Ionicons otherwise.
import { NavigationContainer } from '@react-navigation/native';
import { Text, View } from 'react-native';
import Analytics from './Analytics/Analytics';
// import Profile from './Profile/Profile';
import Calendar from './Calendar/Calendar';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Translate } from './Translate/Translate';

const Tab = createBottomTabNavigator();

Analytics();

function Home() {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>{Translate('Home')}</Text>
    </View>
  );
}

function Privacy() {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>{Translate('Privacy')}</Text>
    </View>
  );
}

function Terms() {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>{Translate('Terms')}</Text>
    </View>
  );
}

export default function App() {

  const linking = {
    prefixes: ['https://point.rocks', 'point://'],
    config: {
        screens: {
            home: 'home',
            details: 'details',
            login: 'login',
            privacy: 'privacy',
            terms: 'terms',
            profile: 'profile',
            booking: 'booking',
            calendar: 'calendar',
        },
    },
  };

  return (
    <NavigationContainer linking={linking} >
      <Tab.Navigator screenOptions={{headerShown: false}}>
        <Tab.Screen name="Home" component={Home} options={{tabBarLabel: Translate('Home')}} />
        <Tab.Screen name="Privacy" component={Privacy} options={{tabBarLabel: Translate('Privacy')}} />
        <Tab.Screen name="Terms" component={Terms} options={{tabBarLabel: Translate('Terms')}} />
        <Tab.Screen name="Calendar" component={Calendar} options={{tabBarLabel: Translate('Calendar')}} />
      </Tab.Navigator>
    </NavigationContainer>
  );
}
