/**
 * @format
 */

import {AppRegistry, Platform} from 'react-native';
import App from './App';
import app from './app.json';

AppRegistry.registerComponent(app.name, () => App);
if (Platform.OS === 'web') {
  AppRegistry.runApplication(app.name, {
    rootTag: document.getElementById('root'),
  });
}
