import React from 'react';
import { AuthProvider, GoogleAuthProvider, OAuthProvider, browserLocalPersistence, getAuth, getRedirectResult, setPersistence, signInWithRedirect, signOut } from 'firebase/auth';
import { Appearance, Image, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Translate } from '../Translate/Translate';

export default function Calendar(): React.JSX.Element {

  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
  const [displayName, setDisplayName] = React.useState<string|null|undefined>('Unknown User');
  const [email, setEmail] = React.useState<string|null|undefined>('Unknown Email');

  const colorScheme = Appearance.getColorScheme();
  const btncolor = (colorScheme === 'dark') ? 'white' : 'black';
  const btnpressedcolor = (colorScheme === 'dark') ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
  const bkgcolor = (colorScheme === 'dark') ? 'black' : 'white';
  var res;

  // Initialize Firebase Authentication and get a reference to the service
  const auth = getAuth();

  if (res === undefined) {

    res = getRedirectResult(auth)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        console.log('Logged in:', result);
        setLoggedIn(true);
        setDisplayName(result?.user.providerData[0].displayName);
        setEmail(result?.user.providerData[0].email);

        // Get the OAuth access token and ID Token
        // const credential = OAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        // const idToken = credential.idToken;
      })
      .catch((error) => {
        // Handle error.
        console.error('Error logging in:', error);
        setDisplayName('');
        setEmail('');
        setLoggedIn(false);
      });
  }

  const handleLogin = async (provider: AuthProvider) => {

    setPersistence(auth, browserLocalPersistence)
    .then(() => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with local persistence.
      console.log('Handle Log in:', provider);
      return signInWithRedirect(auth, provider);
    })
    .catch((error) => {
      // Handle Errors here.
      // Handle the error case here
      console.error('Error logging in:', error);
    });
  };

  const handleMicrosoftLogin = () => {
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('email');
    provider.addScope('profile');
    provider.addScope('Calendars.Read');
    handleLogin(provider);
  };

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    provider.addScope('profile');
    provider.addScope('https://www.googleapis.com/auth/calendar.readonly');
    handleLogin(provider);
  };

  const handleAppleLogin = () => {
    const provider = new OAuthProvider('apple.com');
    handleLogin(provider);
  };

  const handleLogout = () => {

    signOut(auth).then(() => {
      // Sign-out successful.
      console.log('User Logged out');
      setDisplayName('');
      setEmail('');
      setLoggedIn(false);
    }).catch((error) => {
      // An error happened.
      console.error('Error logging out:', error);
    });
  };

  return (
    <View style={[styles.container, { backgroundColor: bkgcolor}]}>
      <ScrollView contentContainerStyle={styles.scrollview}>
      <Text style={[styles.text, {color:btncolor}]}>Sign In</Text>
      <View style={styles.stack}>

        {loggedIn ? (
          <View>
          <Pressable
            style={({ pressed }) => [ { backgroundColor: pressed ? btnpressedcolor : btncolor }, styles.button]}
              onPress={() => handleMicrosoftLogin()}
          >
          <Image style={styles.image} source={require('../assets/Outlook-Calendar-Logo.png')} />
          <Text style={[styles.text, {color:bkgcolor}]}>{Translate('Outlook Calendar')}</Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [ { backgroundColor: pressed ? btnpressedcolor : btncolor }, styles.button]}
            onPress={() => handleGoogleLogin()}
            >
          <Image style={styles.image} source={require('../assets/Google-Calendar-Logo.png')} />
          <Text style={[styles.text, {color:bkgcolor}]}>{Translate('Google Calendar')}</Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [ { backgroundColor: pressed ? btnpressedcolor : btncolor }, styles.button]}
            onPress={() => handleAppleLogin()}
            >
          <Image style={styles.image} source={require('../assets/Apple-Calendar-Logo.png')} />
          <Text style={[styles.text, {color:bkgcolor}]}>{Translate('Apple Calendar')}</Text>
          </Pressable>
          </View>
        ) :
          <View>
          <Text style={[styles.text, {color:bkgcolor}]}>{displayName}</Text>
          <Text style={[styles.text, {color:bkgcolor}]}>{email}</Text>
          <Pressable
            style={({ pressed }) => [ { backgroundColor: pressed ? btnpressedcolor : 'red' }, styles.button]}
            onPress={() => handleLogout()}
            >
          <Text style={[styles.text, {color:bkgcolor}]}>{Translate('Sign Out')}</Text>
          </Pressable>
          </View>
        }

      </View>
      </ScrollView>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
      flex: 1,
      alignContent: 'center',
      justifyContent: 'center',
  },
  button: {
      flexDirection: 'row',
      paddingVertical: 12,
      paddingHorizontal: 32,
      borderRadius: 10,
      elevation: 3,
      marginVertical: 8,
      verticalAlign: 'middle',
  },
  text: {
      verticalAlign: 'middle',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 21,
      fontWeight: 'bold',
      letterSpacing: 0.25,
      marginLeft: 20,
  },
  image: {
      width: 30,
      height: 30,
  },
  stack: {
      margin: 15,
      verticalAlign: 'middle',
      justifyContent: 'center',
  },
  scrollview: {
      alignContent: 'flex-start',
  },
});
